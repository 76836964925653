@import "./styles/_colors";

html, body, #root {
  height: 100%;
}

.app {
  min-height: 100%;
}

.app {
  color: $black;
  background-color: $green-yellow-lightest;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  justify-content: space-between;
  letter-spacing: 0.05em;
  line-height: 24px;
  text-align: left;
}

// don't know why but need this wraped around .appContentWidth
.appPaddingWrapper {
  display: flex;
  justify-content: center;
  padding-left: calc(10% + 40px);
  padding-right: calc(10% + 40px);
  @media (max-width: 1023px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 767px) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.appContentWidth {
  margin: 0;
  max-width: 1000px;
  width: 100%;
}

.pageContent {
  flex-grow: 2;
}
