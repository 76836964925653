@import "../../styles/_colors";

.navBar {
  background-color: rgba($yellow-lighter, 0.85);
  box-sizing: border-box;
  color: $gray;
  font-size: 18px;
  padding-bottom: 30px;
  padding-top: 35px;
  // position: absolute;
  /* TODO: i like postiion absolute here instead of relative but
     need to fix the words on the header image */
  top: 0;
  width: 100%;
  z-index: 99;

  @media (max-width: 1023px) {
    padding-left: 40px;
    padding-right: 40px;
    // position: relative;
  }
}

.body{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;

  .name {
    color: $dark-gray;
    cursor: pointer;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.1em;
    margin-bottom: 0.25em;
    margin-right: 25px;
    .pageOpen {
      color: $black;
    }
    a, a:hover, a:focus, a:active {
      text-decoration: none;
      color: inherit;
    }
    &:hover {
      color: $black;
    }
  }

  .menu {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0.5em;
    margin-right: 0.5em;
    vertical-align: bottom;
    .pageOpen {
      color: $black;
    }
    a, a:hover, a:focus, a:active {
      text-decoration: none;
      color: inherit;
    }
    span {
      color: $dark-gray;
      cursor: pointer;
      margin-right: 30px;
      white-space: nowrap;
      &:hover {
        color: $black;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.languageButton {
  background-color: $white;
  border-radius: 4px;
  border: none;
  color: $black;
  cursor: pointer;
  font-size: 16px;
  margin-left: auto;
  max-height: 2em;
  min-width: 65px;
  padding-left: 0.9em;
  padding-right: 0.9em;
  &:hover {
    background-color: $white-slightly-fadded;
  }
}
